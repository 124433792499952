import React, { useState, useEffect, memo } from "react";
import axios from "axios";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";
import { geoCentroid } from "d3-geo";
import canada from "../assets/data/canada.json";

const mapData = { canada };
//console.log(mapData.canada.objects.provinces.geometries);

const CanadaMap = ({ setTooltipContent }) => {
  const [covidData, setCovidData] = useState([]);

  // useEffect(() => {
  //     fetchCovidData();
  // }, []);

  const fetchCovidData = () => {
    axios
      .get("https://api.opencovid.ca/summary")
      .then((res) => {
        setCovidData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setCovidData([]);
      });
  };

  // topoJSON provinces are not in alphabetical order, must match them individually to fetched province Covid data
  const setProvinceCaseCounts = () => {
    mapData.canada.objects.provinces.geometries[0].properties.caseNumber = 47;
    mapData.canada.objects.provinces.geometries[0].properties.provinceAcronym =
      "AB";
    mapData.canada.objects.provinces.geometries[0].properties.activeCases = 1;
    mapData.canada.objects.provinces.geometries[0].properties.deaths = 1;

    mapData.canada.objects.provinces.geometries[1].properties.caseNumber = 15;
    mapData.canada.objects.provinces.geometries[1].properties.provinceAcronym =
      "SK";
    mapData.canada.objects.provinces.geometries[1].properties.activeCases = 1;
    mapData.canada.objects.provinces.geometries[1].properties.deaths = 1;

    mapData.canada.objects.provinces.geometries[2].properties.caseNumber = 103;
    mapData.canada.objects.provinces.geometries[2].properties.provinceAcronym =
      "MB";
    mapData.canada.objects.provinces.geometries[2].properties.activeCases = 1;
    mapData.canada.objects.provinces.geometries[2].properties.deaths = 1;

    mapData.canada.objects.provinces.geometries[3].properties.caseNumber = -1;
    mapData.canada.objects.provinces.geometries[3].properties.activeCases = 1;
    mapData.canada.objects.provinces.geometries[3].properties.deaths = 1;

    mapData.canada.objects.provinces.geometries[4].properties.caseNumber = -1;
    mapData.canada.objects.provinces.geometries[4].properties.activeCases = 1;
    mapData.canada.objects.provinces.geometries[4].properties.deaths = 1;

    mapData.canada.objects.provinces.geometries[5].properties.caseNumber = -1;
    mapData.canada.objects.provinces.geometries[5].properties.activeCases = 1;
    mapData.canada.objects.provinces.geometries[5].properties.deaths = 1;

    mapData.canada.objects.provinces.geometries[6].properties.caseNumber = -1;
    mapData.canada.objects.provinces.geometries[6].properties.activeCases = 1;
    mapData.canada.objects.provinces.geometries[6].properties.deaths = 1;

    mapData.canada.objects.provinces.geometries[7].properties.caseNumber = -1;
    mapData.canada.objects.provinces.geometries[7].properties.activeCases = 1;
    mapData.canada.objects.provinces.geometries[7].properties.deaths = 1;

    mapData.canada.objects.provinces.geometries[8].properties.caseNumber = 26;
    mapData.canada.objects.provinces.geometries[8].properties.provinceAcronym =
      "ON";
    mapData.canada.objects.provinces.geometries[8].properties.activeCases = 1;
    mapData.canada.objects.provinces.geometries[8].properties.deaths = 1;

    mapData.canada.objects.provinces.geometries[9].properties.caseNumber = -1;
    mapData.canada.objects.provinces.geometries[9].properties.activeCases = 1;
    mapData.canada.objects.provinces.geometries[9].properties.deaths = 1;

    mapData.canada.objects.provinces.geometries[10].properties.caseNumber = -1;
    mapData.canada.objects.provinces.geometries[10].properties.activeCases = 1;
    mapData.canada.objects.provinces.geometries[10].properties.deaths = 1;

    mapData.canada.objects.provinces.geometries[11].properties.caseNumber = 15;
    mapData.canada.objects.provinces.geometries[11].properties.provinceAcronym =
      "BC";
    mapData.canada.objects.provinces.geometries[11].properties.activeCases = 1;
    mapData.canada.objects.provinces.geometries[11].properties.deaths = 1;
    mapData.canada.objects.provinces.geometries[12].properties.caseNumber = 23;
    mapData.canada.objects.provinces.geometries[12].properties.provinceAcronym =
      "QC";
    mapData.canada.objects.provinces.geometries[12].properties.activeCases = 1;
    mapData.canada.objects.provinces.geometries[12].properties.deaths = 1;
  };

  // Calculate and return centroid coordinates of province for positioning daily reported cases text
  const centroid = (prov) => {
    var coords = geoCentroid(prov);

    if (prov.properties.NAME === "Nunavut") {
      coords[0] = coords[0] - 8;
      coords[1] = coords[1] - 6;
    } else if (prov.properties.NAME === "Northwest Territories") {
      coords[0] = coords[0] - 1;
      coords[1] = coords[1] - 2;
    } else if (prov.properties.NAME === "Newfoundland  & Labrador") {
      coords[0] = coords[0] - 1;
    }

    return coords;
  };

  // Set colour of province based on number of daily reported cases
  const setCaseColour = (prov) => {
    var count = prov.properties.caseNumber;
    if (count < 0) {
      return "#FFFFFF";
    } else if (count < 20) {
      return "#F0A150";
    } else if (count < 50) {
      return "#f09537";
    } else if (count < 75) {
      return "#f48020";
    } else if (count <= 100) {
      return "#f0750f";
    } else if (count > 125) {
      return "#c76706";
    } else {
      return "#a85603";
    }
  };

  // Once Covid data is fetched, add data to topoJSON and render map
  //   if (covidData.length !== 0) {
  setProvinceCaseCounts();

  //console.log(mapData.canada.objects.provinces.geometries);

  return (
    <ComposableMap
      data-tip=""
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [100, -45, 0],
        scale: 400,
      }}
      style={{
        width: "1600px",
        height: "auto",
        marginLeft: "-450px",
        marginTop: "-150px",
        pointerEvents: "none",
      }}
    >
      <Geographies geography={mapData.canada}>
        {({ geographies }) => (
          <>
            {geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                stroke="#000000"
                strokeWidth={0.5}
                fill={setCaseColour(geo)}
                geography={geo}
                style={{
                  hover: {
                    filter: "brightness(110%)",
                  },
                }}
                onMouseEnter={() => {
                  const activeCases = geo.properties.activeCases;
                  const deaths = geo.properties.deaths;

                  setTooltipContent(
                    `Active Cases: ${activeCases} Deaths: ${deaths}`
                  );
                }}
                onMouseLeave={() => {
                  setTooltipContent("");
                }}
              />
            ))}

            {geographies.map((geo) => {
              if (geo.properties.caseNumber < 0) {
                return;
                return (
                  <Annotation
                    key={geo.rsmKey}
                    subject={centroid(geo)}
                    dx={10}
                    dy={10}
                    connectorProps={{
                      stroke: "#000000",
                      strokeWidth: 1,
                      strokeLinecap: "round",
                    }}
                  >
                    <text fontSize="6px" textAnchor="end" x="12" y="2">
                      {geo.properties.caseNumber}
                    </text>
                  </Annotation>
                );
              }
              if (
                geo.properties.NAME === "Prince Edward Island" ||
                geo.properties.NAME === "Nova Scotia"
              ) {
                return;
                return (
                  <Annotation
                    key={geo.rsmKey}
                    subject={centroid(geo)}
                    dx={10}
                    dy={10}
                    connectorProps={{
                      stroke: "#000000",
                      strokeWidth: 1,
                      strokeLinecap: "round",
                    }}
                  >
                    <text fontSize="6px" textAnchor="end" x="12" y="2">
                      {geo.properties.caseNumber}
                    </text>
                  </Annotation>
                );
              } else if (geo.properties.NAME === "New Brunswick") {
                return;
                return (
                  <Annotation
                    key={geo.rsmKey}
                    subject={centroid(geo)}
                    dx={10}
                    connectorProps={{
                      stroke: "#000000",
                      strokeWidth: 1,
                      strokeLinecap: "round",
                    }}
                  >
                    <text fontSize="6px" textAnchor="end" x="12" y="2">
                      {geo.properties.caseNumber}
                    </text>
                  </Annotation>
                );
              } else {
                return (
                  <Marker
                    key={geo.rsmKey}
                    coordinates={centroid(geo)}
                    fill="black"
                  >
                    <text fontSize="6px" textAnchor="middle">
                      {geo.properties.caseNumber +
                        " " +
                        geo.properties.provinceAcronym}
                    </text>
                  </Marker>
                );
              }
            })}
          </>
        )}
      </Geographies>
    </ComposableMap>
  );
};

export default memo(CanadaMap);
