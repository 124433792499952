import axios from "axios";

// myLogOut
const logout = () => {
  localStorage.setItem("user", null);
  window.location.href = "/";
  window.location.reload();
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // You can add other default settings here if needed
});

axiosInstance.interceptors.response.use(
  (response) => response, // If the response is successful, just return the response
  (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        logout(); // Call the logout function
      }
    }
    return Promise.reject(error); // Always reject the error to handle it in your components
  }
);

export { axiosInstance };
