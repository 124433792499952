import { useState } from "react";
import { useAuth } from "../components/Auth/useAuth";
import { axiosInstance } from "../components/axiosInstance";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import img from "../assets/images/overlay_4.jpg";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { alpha, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";

export const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Replace with actual authentication logic
      const response = await axiosInstance.post(
        `/partners/token`,
        {
          username: username,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      await login({ username: username, token: response.data.access_token });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Unauthorized: Invalid username or password.");
      } else {
        alert("An error occurred. Please try again later.");
        console.error("Error during login:", error);
      }
    }
  };

  return (
    <div
      style={{
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        background: `linear-gradient(to bottom, rgba(249, 250, 251, 0.8), rgba(249, 250, 251, 0.8)),url(${img})`,
      }}
      className="flex items-center justify-center"
    >
      <Box>
        <Paper sx={{ borderRadius: "16px" }} elevation={3}>
          {" "}
          <form
            style={{
              width: 420,

              padding: 40,
            }}
            onSubmit={handleLogin}
          >
            <Typography variant="h4" sx={{ my: "24px" }}>
              Fieldprint Calculator <br />
              Partner App
            </Typography>
            <div>
              <TextField
                className="w-full"
                name="email"
                label="Partner ID"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />

              <FormControl
                sx={{ width: "100%", mt: "24px" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Access Code
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Access Code"
                />
              </FormControl>
            </div>

            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="contained"
              type="submit"
              sx={{
                marginTop: "24px",
                backgroundColor: "#F15D22",
                color: "white",
                "&:hover": {
                  backgroundColor: "#a8370b",
                },
              }}
            >
              Log In
            </Button>
          </form>
        </Paper>
      </Box>
    </div>
  );
};
