import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

export default function InviteAssociates({
  associateEmails,
  InvitationsObject,
  setActiveStep,
}) {
  const [checked, setChecked] = React.useState([]);

  // Handle individual toggle
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  // Handle select all / deselect all
  const handleSelectAll = () => {
    if (checked.length === associateEmails.length) {
      // If all items are checked, uncheck all
      setChecked([]);
    } else {
      // Otherwise, check all items
      setChecked(associateEmails);
    }
  };

  // Handle sending invitations
  const handleSendInvitations = () => {
    console.log("Checked Emails: ", checked);

    let checkedEmails = checked.reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});

    const invitations = [];

    for (const farmer_id in InvitationsObject) {
      const email = InvitationsObject[farmer_id].email
        .toString()
        .trim()
        .toLowerCase();

      if (email in checkedEmails)
        invitations.push(InvitationsObject[farmer_id]);
    }
    console.log("invitations ", invitations);
    setActiveStep(3);
  };

  // Determine if all items are selected
  const allSelected = checked.length === associateEmails.length;

  return (
    <div className="w-full h-full overflow-scoll flex flex-col items-center ">
      <div className="align-self-start">
        <Button
          variant="contained"
          onClick={handleSelectAll}
          sx={{ marginBottom: 2, marginRight: 2 }}
        >
          {allSelected ? "Deselect All" : "Select All"}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSendInvitations}
          sx={{ marginBottom: 2 }}
          disabled={checked.length === 0} // Disable button if no emails are checked
        >
          Send Invitations
        </Button>
      </div>

      <List
        dense
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      >
        {associateEmails.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value}`;
          return (
            <ListItem
              key={value}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={handleToggle(value)}
                  checked={checked.indexOf(value) !== -1}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar alt={`Avatar ${value}`} />
                </ListItemAvatar>
                <ListItemText id={labelId} primary={value} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
