import MapCoordinates from "../components/Map/Map_Dataset";
import { getNearestCoordinate } from "../components/Map/getNearestCoordinates";

export default function flattenData(ParsedData, setRequestFilters) {
  const InputObject = {};

  // FILTER SETS
  const FILTERS = {};

  const MachinesDict = ParsedData["Machines"];

  for (const cropyear_id in ParsedData["Cropyears"]) {
    const cropyearObj = ParsedData["Cropyears"][cropyear_id];
    const fieldObj = ParsedData["Fields"][cropyearObj.field_id];
    const farmObj = ParsedData["Farms"][fieldObj.farm_id];

    // concatenate province crop year, and crop for example "MB2022Spring wheat"
    let key = farmObj.province + cropyearObj["crop_year"] + cropyearObj.crop;

    // Initialize Emtpy Rows for this unique key
    if (key in InputObject === false) {
      InputObject[key] = [];
    }

    const ROW = createRow(MachinesDict, farmObj, fieldObj, cropyearObj);

    // get unique sets
    if (farmObj.province in FILTERS === false) {
      FILTERS[farmObj.province] = {};
    }

    if (cropyearObj.crop_year in FILTERS[farmObj.province] === false) {
      FILTERS[farmObj.province][cropyearObj.crop_year] = {};
    }

    // farmObj.province
    // cropyearObj.crop_year
    // cropyearObj.crop
    FILTERS[farmObj.province][cropyearObj.crop_year][cropyearObj.crop] = true;

    InputObject[key].push(ROW);
  }

  setRequestFilters(FILTERS);

  return InputObject;
}

function createRow(MachinesDict, farmObj, fieldObj, cropyearObj) {
  let fert = cropyearObj.planting_applied_fertilizer;

  var EspartacoDividend = 1;

  if (fert === true || fert === "true" || fert === "True" || fert === "TRUE") {
    EspartacoDividend = 2;
  }

  // GET ECODISTRICT, SOIL_CODE, AND SLC_POLYGON USING GET NEAREST COORDINATES AND ESPARTACO'S DATASETS
  const SoilData = getNearestCoordinate(
    farmObj.province,
    parseFloat(fieldObj.latitude),
    parseFloat(fieldObj.longitude),
    MapCoordinates
  );

  fieldObj["ecodistrict"] = SoilData.Ecodistrict;
  fieldObj["slc_polygon"] = SoilData.SLCpolygon;
  fieldObj["soil_code"] = SoilData.SOIL_CODE;

  console.log(
    "AQUI: ",
    farmObj.province,
    parseFloat(fieldObj.latitude),
    parseFloat(fieldObj.longitude),
    fieldObj["ecodistrict"],
    fieldObj["slc_polygon"],
    fieldObj["soil_code"]
  );

  var row = [
    [cropyearObj.cropyear_id, "UniqueID"],
    [farmObj.farm_id, "Farm_ID"],
    [fieldObj.name, "Field_Name"],
    ["", "Contract Number"], // null
    ["", "Variety"], // null
    ["", "Delivery Location"], // null
    ["", "Quarter"], // null
    ["", "Section"], // null
    ["", "Township"], // null
    ["", "Range"], // null
    ["", "Meridian"], // null
    [cropyearObj.crop_year, "Crop_Year"],
    [cropyearObj.crop, "Crop"],
    [cropyearObj.crop_frequency, "Freq_Crop"],
    [cropyearObj.yield, "Yield"],
    [cropyearObj.yield_units, "Yield_Units"],
    [cropyearObj.previous_crop, "Prior_Crop"],
    [
      cropyearObj.cultivation_1_machineId &&
        MachinesDict[cropyearObj.cultivation_1_machineId].hp,
      "Cult1_Size",
    ],
    ["horsepower", "Cult1_Units"],
    [cropyearObj.cultivation_1_hours, "Cult1_Hrs"],
    [
      cropyearObj.cultivation_1_machineId &&
        MachinesDict[cropyearObj.cultivation_1_machineId].acre_hour,
      "Cult1_AcHr",
    ],
    [
      cropyearObj.cultivation_2_machineId &&
        MachinesDict[cropyearObj.cultivation_2_machineId].hp,
      "Cult2_Size",
    ],
    ["horsepower", "Cult2_Units"],
    [cropyearObj.cultivation_2_hours, "Cult2_Hrs"],
    [
      cropyearObj.cultivation_2_machineId &&
        MachinesDict[cropyearObj.cultivation_2_machineId].acre_hour,
      "Cult2_AcHr",
    ],
    [
      cropyearObj.cultivation_3_machineId &&
        MachinesDict[cropyearObj.cultivation_3_machineId].hp,
      "Cult3_Size",
    ],
    ["horsepower", "Cult3_Units"],
    [cropyearObj.cultivation_3_hours, "Cult3_Hrs"],
    [
      cropyearObj.cultivation_3_machineId &&
        MachinesDict[cropyearObj.cultivation_3_machineId].acre_hour,
      "Cult3_AcHr",
    ],
    [
      cropyearObj.cultivation_4_machineId &&
        MachinesDict[cropyearObj.cultivation_4_machineId].hp,
      "Cult4_Size",
    ],
    ["horsepower", "Cult4_Units"],
    [cropyearObj.cultivation_4_hours, "Cult4_Hrs"],
    [
      cropyearObj.cultivation_4_machineId &&
        MachinesDict[cropyearObj.cultivation_4_machineId].acre_hour,
      "Cult4_AcHr",
    ],
    [
      cropyearObj.planting_machineId &&
        MachinesDict[cropyearObj.planting_machineId].hp,
      "Seeding_Size",
    ],
    ["horsepower", "Seeding_Units"],
    [
      cropyearObj.planting_hours
        ? cropyearObj.planting_hours / EspartacoDividend
        : "",
      "Seeding_Hrs",
    ],
    [
      cropyearObj.planting_machineId &&
        MachinesDict[cropyearObj.planting_machineId].acre_hour,
      "Seeding_AcHr",
    ],
    [
      cropyearObj.seed_fertilizer_machineId &&
        MachinesDict[cropyearObj.seed_fertilizer_machineId].hp,
      "Seed_Size",
    ],
    ["horsepower", "Seed_Units"],
    [
      cropyearObj.seed_fertilizer_hours
        ? cropyearObj.seed_fertilizer_hours / EspartacoDividend
        : "",
      "Seed_Hrs",
    ],
    [
      cropyearObj.seed_fertilizer_machineId &&
        MachinesDict[cropyearObj.seed_fertilizer_machineId].acre_hour,
      "Seed_AcHr",
    ],
    [cropyearObj.seed_fertilizer_date, "Seed_FertDate"],
    [cropyearObj.nitrogen_seed_rate, "Seed_N"],
    [cropyearObj.phosphorus_seed_rate, "Seed_P"],
    [cropyearObj.potassium_seed_rate, "Seed_K"],
    [cropyearObj.sulphur_seed_rate, "Seed_S"],
    [cropyearObj.micronutrients_seed_rate, "Seed_OtherFert"],
    [
      cropyearObj.preseed_fertilizer_machineId &&
        MachinesDict[cropyearObj.preseed_fertilizer_machineId].hp,
      "PreSeed_Size",
    ],
    ["horsepower", "PreSeed_Units"],
    [cropyearObj.preseed_fertilizer_hours, "PreSeed_Hrs"],
    [
      cropyearObj.preseed_fertilizer_machineId &&
        MachinesDict[cropyearObj.preseed_fertilizer_machineId].acre_hour,
      "PreSeed_AcHr",
    ],
    [cropyearObj.preseed_fertilizer_date, "PreSeed_FertDate"],
    [cropyearObj.nitrogen_preseed_rate, "PreSeed_N"],
    [cropyearObj.phosphorus_preseed_rate, "PreSeed_P"],
    [cropyearObj.potassium_preseed_rate, "PreSeed_K"],
    [cropyearObj.sulphur_preseed_rate, "PreSeed_S"],
    [cropyearObj.micronutrients_preseed_rate, "PreSeed_OtherFert"],
    [
      cropyearObj.postseed_fertilizer_machineId &&
        MachinesDict[cropyearObj.postseed_fertilizer_machineId].hp,
      "PostSeed_Size",
    ],
    ["horsepower", "PostSeed_Units"],
    [cropyearObj.postseed_fertilizer_hours, "PostSeed_Hrs"],
    [
      cropyearObj.postseed_fertilizer_machineId &&
        MachinesDict[cropyearObj.postseed_fertilizer_machineId].acre_hour,
      "PostSeed_AcHr",
    ],
    [cropyearObj.postseed_fertilizer_date, "PostSeed_FertDate"],
    [cropyearObj.nitrogen_postseed_rate, "PostSeed_N"],
    [cropyearObj.phosphorus_postseed_rate, "PostSeed_P"],
    [cropyearObj.potassium_postseed_rate, "PostSeed_K"],
    [cropyearObj.sulphur_postseed_rate, "PostSeed_S"],
    [cropyearObj.micronutrients_postseed_rate, "PostSeed_OtherFert"],

    [
      cropyearObj.pesticide_1_machineId &&
        MachinesDict[cropyearObj.pesticide_1_machineId].hp,
      "Pest1_Size",
    ],
    ["horsepower", "Pest1_Units"],
    [cropyearObj.pesticide_1_hours, "Pest1_Hrs"],
    [
      cropyearObj.pesticide_1_machineId &&
        MachinesDict[cropyearObj.pesticide_1_machineId].acre_hour,
      "Pest1_AcHr",
    ],

    [
      cropyearObj.pesticide_2_machineId &&
        MachinesDict[cropyearObj.pesticide_2_machineId].hp,
      "Pest2_Size",
    ],
    ["horsepower", "Pest2_Units"],
    [cropyearObj.pesticide_2_hours, "Pest2_Hrs"],
    [
      cropyearObj.pesticide_2_machineId &&
        MachinesDict[cropyearObj.pesticide_2_machineId].acre_hour,
      "Pest2_AcHr",
    ],

    [
      cropyearObj.pesticide_3_machineId &&
        MachinesDict[cropyearObj.pesticide_3_machineId].hp,
      "Pest3_Size",
    ],
    ["horsepower", "Pest3_Units"],
    [cropyearObj.pesticide_3_hours, "Pest3_Hrs"],
    [
      cropyearObj.pesticide_3_machineId &&
        MachinesDict[cropyearObj.pesticide_3_machineId].acre_hour,
      "Pest3_AcHr",
    ],

    [
      cropyearObj.pesticide_4_machineId &&
        MachinesDict[cropyearObj.pesticide_4_machineId].hp,
      "Pest4_Size",
    ],
    ["horsepower", "Pest4_Units"],
    [cropyearObj.pesticide_4_hours, "Pest4_Hrs"],
    [
      cropyearObj.pesticide_4_machineId &&
        MachinesDict[cropyearObj.pesticide_4_machineId].acre_hour,
      "Pest4_AcHr",
    ],

    [
      cropyearObj.pesticide_5_machineId &&
        MachinesDict[cropyearObj.pesticide_5_machineId].hp,
      "Pest5_Size",
    ],
    ["horsepower", "Pest5_Units"],
    [cropyearObj.pesticide_5_hours, "Pest5_Hrs"],
    [
      cropyearObj.pesticide_5_machineId &&
        MachinesDict[cropyearObj.pesticide_5_machineId].acre_hour,
      "Pest5_AcHr",
    ],

    [
      cropyearObj.swather_machineId &&
        MachinesDict[cropyearObj.swather_machineId].hp,
      "Swath_Size",
    ],
    ["horsepower", "Swath_Units"],
    [cropyearObj.swather_hours, "Swath_Hrs"],
    [
      cropyearObj.swather_machineId &&
        MachinesDict[cropyearObj.swather_machineId].acre_hour,
      "Swath_AcHr",
    ],

    [
      cropyearObj.combine_machineId &&
        MachinesDict[cropyearObj.combine_machineId].hp,
      "Comb_Size",
    ],
    ["horsepower", "Comb_Units"],
    [cropyearObj.combine_hours, "Comb_Hrs"],
    [
      cropyearObj.combine_machineId &&
        MachinesDict[cropyearObj.combine_machineId].acre_hour,
      "Comb_AcHr",
    ],
    [cropyearObj.combine_avg_speed, "Comb_Speed"],

    [cropyearObj.crop_drying_type, "Drying_Type"],
    [cropyearObj.crop_drying_fuel, "Drying_Fuel"],
    [cropyearObj.moisture_before_drying, "Drying_Before"],
    [cropyearObj.moisture_after_drying, "Drying_After"],
    ["", "Notes_Farm"], // Null
    ["", "Notes_Field"], // Null
    ["", "Notes_Year"], // Null
    ["", "Nitro_Pre_Source"], // Null
    ["", "Nitro_Pre_Placement"], // Null
    ["", "Nitro_Pre_Opener"], // Null
    ["", "Nitro_Pre_Spacing"], // Null
    ["", "Nitro_With_Source"], // Null
    ["", "Nitro_With_Placement"], // Null
    ["", "Nitro_With_Opener"], // Null
    ["", "Nitro_With_Spacing"], // Null
    ["", "Nitro_Post_Source"], // Null
    ["", "Nitro_Post_Placement"], // Null
    [fieldObj.field_size, "Field_Size"],
    [fieldObj.surface_form, "Surface_Form"],
    [fieldObj.slope_class, "Slope"],
    ["", "Wind_Erosion"], //Null
    [fieldObj.soil_type, "Soil_Type"],
    [fieldObj.surface_soil_texture, "Soil_Texture"],
    [fieldObj.soil_code, "Soil_Code"],
    [fieldObj.previous_tillage_regime, "Till_Previous"],
    [fieldObj.tillage_regime, "Till_Current"],
    [fieldObj.regime_change_date, "Till_ChangeYear"],
    ["", "Fallow_Previous"], //Null
    ["", "Fallow_Current"], //Null
    ["", "Fallow_ChangeYear"], //Null
    ["", "Perennial"], //Null
    ["", "Perennial_ChangeYear"], //Null
    ["", "LateSeed"], //Null
    ["", "Drained"], //Null
    ["", "Drained_Recent"], //Null
    [fieldObj.ecodistrict, "Ecodistrict"],
    ["", "LLD4Lookup"], //Null
    ["", "Municipality"],
    [farmObj.province, "Province"],
    ["", "Advisor"], // Null
    ["", "ProducerCode"], // Null
    ["", "FieldCode"], // Null
    [fieldObj.slc_polygon, "SLC Polygon"],
    ["", "Ecodistrict_1"], //Null
    ["", "PeerA"], //null
    ["", "PeerB"], //null
    ["", "PeerC"], //null
    ["", "Comments Reviewed"], //null
    ["", "Eco_ID"], //null
    ["", "Oat-Growing Acres"], //null
    ["", "Use for 2020 Aggregate"], //null
    ["", "Row for Reporting"], //null
  ];

  return row.map((pair) => {
    let val = pair[0];
    if (typeof val !== "undefined") return pair[0];
    else return "";
  });
}
