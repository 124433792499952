import React, { useCallback, useState, useEffect } from "react";
import { useAuth } from "../components/Auth/useAuth";
import SheetPreview from "../components/SheetPreview";
import DragDrop from "../components/DragDrop";
import InviteAssociates from "../components/InviteAssociates";
import GenerateReport from "../components/GenerateReport";
import Stepper from "../components/Stepper";
import { axiosInstance } from "../components/axiosInstance";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import img from "../assets/images/overlay_4.jpg";
import Nav from "../components/Nav";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#F15D22", // This is an orange looking color
//     },
//     secondary: {
//       main: "#ffcc80", //Another orange-ish color
//     },
//   },
// });

const STEPS = [
  "Import your data",
  "Preview",
  "Invite Farmers",
  "Generate Results",
];
function ReportPage() {
  const { user } = useAuth();
  // STEPPER STATE
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [previewData, setPreviewData] = useState([]);
  const [sheetNames, setSheetNames] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState("");
  const [workbookState, setWorkBookState] = useState(false);
  const [associateEmails, setAssociateEmails] = useState([]);
  const [InvitationsObject, setInvitationsObject] = useState({});
  const [InputObject, setInputObject] = useState({});
  const [RequestFilters, setRequestFilters] = useState(false);

  useEffect(() => {
    async function getUser() {
      const response = await axiosInstance.get("/partners/users/me", {
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
      });
      console.log(response.data);
    }

    getUser();
  }, []);

  return (
    <div className="flex w-screen h-screen">
      <Nav activePage={"report"} />

      <div
        style={{
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          background: `linear-gradient(to bottom, rgba(249, 250, 251, 0.8), rgba(249, 250, 251, 0.8)),url(${img})`,
        }}
      >
        <div className="w-full h-full overflow-scroll flex justify-center items-center">
          <Paper sx={{ borderRadius: "16px" }} elevation={3}>
            <div className="max-w-[800px] bg-white w-[800px] h-[800px] px-8 py-6 border-[0.5px] border-black rounded-xl shadow-lg">
              <h1 className="text-2xl">{STEPS[activeStep]}</h1>
              <div className="h-[550px]">
                {activeStep === 0 && (
                  <DragDrop
                    setPreviewData={setPreviewData}
                    setSheetNames={setSheetNames}
                    setSelectedSheet={setSelectedSheet}
                    workbookState={workbookState}
                    setWorkBookState={setWorkBookState}
                    setAssociateEmails={setAssociateEmails}
                    setInvitationsObject={setInvitationsObject}
                    setInputObject={setInputObject}
                    setRequestFilters={setRequestFilters}
                    setActiveStep={setActiveStep}
                  />
                )}
                {activeStep === 1 && (
                  <SheetPreview
                    previewData={previewData}
                    setPreviewData={setPreviewData}
                    sheetNames={sheetNames}
                    selectedSheet={selectedSheet}
                    setSelectedSheet={setSelectedSheet}
                    workbookState={workbookState}
                  />
                )}
                {activeStep === 2 && (
                  <InviteAssociates
                    associateEmails={associateEmails}
                    InvitationsObject={InvitationsObject}
                    setActiveStep={setActiveStep}
                  />
                )}
                {activeStep === 3 && (
                  <GenerateReport
                    InputObject={InputObject}
                    RequestFilters={RequestFilters}
                  />
                )}
              </div>
              <Stepper
                steps={STEPS}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                skipped={skipped}
                setSkipped={setSkipped}
              />
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default ReportPage;
