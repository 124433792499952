import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";

function ExcelReader({
  previewData,
  setPreviewData,
  sheetNames,
  selectedSheet,
  setSelectedSheet,
  workbookState,
}) {
  const handleSheetChange = (e) => {
    const sheetName = e.target.value;
    setSelectedSheet(sheetName);

    // Re-parse the data for the selected sheet

    const worksheet = workbookState.Sheets[sheetName];
    const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const headers = sheetData[0];

    // Transform the rest of the data into an array of objects
    // const result = sheetData.slice(1).map((row) => {
    //   let obj = {};
    //   headers.forEach((header, index) => {
    //     obj[header] = row[index];
    //   });
    //   return obj;
    // });
    // console.log(result);

    setPreviewData(sheetData);
  };

  return (
    <div style={styles.container}>
      {sheetNames.length > 0 && (
        <div style={styles.controls}>
          <label htmlFor="sheetSelect">Select Sheet: </label>
          <select
            id="sheetSelect"
            value={selectedSheet}
            onChange={handleSheetChange}
            style={styles.select}
            className="border-2 border-slate-300"
          >
            {sheetNames.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
      )}

      <div style={styles.tableContainer}>
        <table style={styles.table}>
          <thead>
            <tr>
              {previewData[0] &&
                previewData[0].map((col, index) => (
                  <th key={index} style={styles.tableHeader}>
                    {col}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {previewData.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} style={styles.tableCell}>
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
  },
  error: {
    color: "red",
    position: "absolute",
    top: "80px",
  },
  dropZone: {
    width: "100%",
    height: "100px",
    border: "2px dashed #0087F7",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginBottom: "20px",
  },
  controls: {
    marginBottom: "20px",
  },
  select: {
    padding: "5px",
    fontSize: "16px",
  },
  tableContainer: {
    maxHeight: "500px",
    overflow: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
    border: "1px solid #ddd",
    padding: "8px",
  },
  tableCell: {
    border: "1px solid #ddd",
    padding: "8px",
  },
};

export default ExcelReader;
