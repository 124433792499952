import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { useAuth } from "./Auth/useAuth";

import flattenData from "./flattenData";
import { axiosInstance } from "./axiosInstance";

function DragDrop({
  setPreviewData,
  setSheetNames,
  setSelectedSheet,
  workbookState,
  setWorkBookState,
  setAssociateEmails,
  setInvitationsObject,
  setInputObject,
  setRequestFilters,
  setActiveStep,
}) {
  const [error, setError] = useState("");
  const { user } = useAuth();

  async function handlePostFile(file) {
    try {
      const formData = new FormData();
      formData.append("file", file); // Add the file to FormData

      const response = await axiosInstance.post("/partners/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + user.token,
        },
      });

      console.log("File successfully uploaded:", response.data);
    } catch (uploadError) {
      console.error("File upload failed:", uploadError);
      setError("File upload failed. Please try again.");
    }
  }
  function fillInvitationsObject(ParsedData) {
    const Invitations = [];

    for (const farmer_id in ParsedData["Farmers"]) {
      Invitations[farmer_id] = {
        ...ParsedData["Farmers"][farmer_id],
        machines: [],
        farms: [],
        fields: [],
        cropyears: [],
      };
    }

    for (const machine_id in ParsedData["Machines"]) {
      let owner_id = ParsedData["Machines"][machine_id]["owner_id"];
      Invitations[owner_id]["machines"].push(
        ParsedData["Machines"][machine_id]
      );
    }

    for (const farm_id in ParsedData["Farms"]) {
      let owner_id = ParsedData["Farms"][farm_id]["owner_id"];
      Invitations[owner_id]["farms"].push(ParsedData["Farms"][farm_id]);
    }

    for (const field_id in ParsedData["Fields"]) {
      let owner_id = ParsedData["Fields"][field_id]["owner_id"];
      Invitations[owner_id]["fields"].push(ParsedData["Fields"][field_id]);
    }

    for (const cropyear_id in ParsedData["Cropyears"]) {
      let owner_id = ParsedData["Cropyears"][cropyear_id]["owner_id"];
      Invitations[owner_id]["cropyears"].push(
        ParsedData["Cropyears"][cropyear_id]
      );
    }

    return Invitations;
  }

  function parseData(workbook) {
    const ParsedData = {};

    for (const sheetName of workbook.SheetNames) {
      const worksheet = workbook.Sheets[sheetName];

      const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const headers = sheetData[0];
      // Transform the rest of the data into an array of objects
      let TmpDict = {};

      sheetData.slice(1).map((row) => {
        let obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index];
        });

        switch (sheetName) {
          case "Farmers":
            TmpDict[obj["farmer_id"]] = obj;
            break;
          case "Machines":
            TmpDict[obj["machine_id"]] = obj;
            break;
          case "Farms":
            TmpDict[obj["farm_id"]] = obj;
            break;
          case "Fields":
            TmpDict[obj["field_id"]] = obj;
            break;
          case "Cropyears":
            TmpDict[obj["cropyear_id"]] = obj;
            break;
        }
      });
      // console.log(result);

      ParsedData[sheetName] = TmpDict;
    }
    return ParsedData;
  }

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file) {
      setError(""); // Clear any previous error messages
      const reader = new FileReader();
      reader.onload = (event) => {
        const rawData = new Uint8Array(event.target.result);

        const workbook = XLSX.read(rawData, { type: "array" });
        // 1. PARSE THE WORKBOOK DATA INTO AN OBJECT
        const ParsedData = parseData(workbook);

        // 2. FLATTEN DATA INTO ROWS IN AN OBJECT THAT FILTERS PROVINCE -> CROP -> YEAR
        const INPUT_OBJECT = flattenData(ParsedData, setRequestFilters);
        setInputObject(INPUT_OBJECT);

        setWorkBookState(workbook);
        // Get sheet names and set the first one as default
        const firstSheetName = workbook.SheetNames[0];
        setSheetNames(workbook.SheetNames);
        setSelectedSheet(firstSheetName);

        // Parse the data of the first sheet
        const worksheet = workbook.Sheets[firstSheetName];

        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Extract emails from the "Emails" column
        const emailColumnIndex = sheetData[0].indexOf("email");
        if (emailColumnIndex !== -1) {
          const emails = sheetData
            .slice(1) // Skip header row
            .map((row) => row[emailColumnIndex])
            .filter((email) => email) // Filter out empty values
            .map((email) => email.toString().trim().toLowerCase()); // Normalize case

          // Remove duplicates and sort
          const uniqueEmails = [...new Set(emails)].sort();

          // 3. IF THE EMAIL COLUMN EXISTS FILL THE INVITATIONS OBJECT
          const InvitationsObject = fillInvitationsObject(ParsedData);
          setInvitationsObject(InvitationsObject);

          setAssociateEmails(uniqueEmails);
        } else {
          setError('No "Emails" column found in the first sheet.');
        }

        setPreviewData(sheetData);

        // Move Stepper
        setActiveStep(1);

        // POST the file to your backend
        handlePostFile(file);
      };

      reader.readAsArrayBuffer(file);
    }
  }, []);

  const onDropRejected = useCallback((rejectedFiles) => {
    const file = rejectedFiles[0];
    if (file && file.errors.length > 0) {
      if (file.errors[0].code === "file-too-large") {
        setError("File is too large. Maximum size is 5 MB.");
      }
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: ".xlsx",
    maxSize: 5 * 1024 * 1024, // 5 MB size limit
  });

  return (
    <div className="flex items-center" style={styles.container}>
      <div {...getRootProps()} style={styles.dropZone}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop a .xlsx file here, or click to select one</p>
        {error && <p style={styles.error}>{error}</p>}
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  error: {
    color: "red",
    position: "absolute",
    top: "80px",
  },
  dropZone: {
    width: "100%",
    height: "100px",
    border: "2px dashed #D15F22",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginBottom: "20px",
  },
  controls: {
    marginBottom: "20px",
  },
  select: {
    padding: "5px",
    fontSize: "16px",
  },
  tableContainer: {
    maxHeight: "400px",
    overflow: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
    border: "1px solid #ddd",
    padding: "8px",
  },
  tableCell: {
    border: "1px solid #ddd",
    padding: "8px",
  },
};

export default DragDrop;
