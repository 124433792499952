import React, { useState, useEffect } from "react";
import { axiosInstance } from "../components/axiosInstance";
import { useAuth } from "../components/Auth/useAuth";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Paper } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import FarmIcon from "../assets/icons/barn.png";
import FarmerIcon from "../assets/icons/farmer.png";
import FieldIcon from "../assets/icons/farming.png";
import CropyearIcon from "../assets/icons/plant.png";
import AcresIcon from "../assets/icons/acres.png";
import FertilizerIcon from "../assets/icons/fertilizer.png";
import MachinesIcon from "../assets/icons/machines.png";
import MachineHours from "../assets/icons/machineHours.png";
import * as ReactDOM from "react-dom";
import { Tooltip } from "react-tooltip";
import Nav from "../components/Nav.jsx";
import Chart from "react-apexcharts";

import CanadaMap from "../components/CanadaMap.jsx";

export default function DashboardPage({}) {
  const { user } = useAuth();
  const [userData, setUserData] = useState(false);

  useEffect(() => {
    async function getUser() {
      const response = await axiosInstance.get("/partners/users/me", {
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
      });

      console.log(response.data);
      setUserData(response.data);
    }
    getUser();
  }, []);

  const [username, setUserName] = useState("");

  if (!userData) return;

  return (
    <div className="flex relative w-screen h-screen">
      <Nav activePage={"dashboard"} />
      <div className="w-screen pl-[200px] h-[40px] fixed bg-[#F15D22] flex justify-center items-center top-0 z-40">
        {" "}
        <p className="text-white font-medium">
          This is a <span className="font-black">Demo</span> of the Partner
          Dashboard.
        </p>
      </div>

      <div className="w-full h-full  bg-[rgb(243,243,243)] py-[88px] px-[40px] overflow-scroll flex justify-center">
        <main className="max-w-[1512px]  w-full h-full ">
          {/* PROFILE */ <ProfileSection userData={userData} />}

          {/* INSIGHTS SECTION */}
          <InsightsHeader userData={userData} />

          {/* MAP SECTION */}

          <div className="w-full h-[550px] mb-5">
            <ColumnRow title={"Partner Farms Across Canada"}>
              {" "}
              <div className="w-full flex justify-center">
                <InsightsMap />
              </div>
            </ColumnRow>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pb-[100px]">
            <ColumnRow title={"Crop Rotation Counts by Year"}>
              {" "}
              <InsightsBarChart />{" "}
            </ColumnRow>
            <ColumnRow title={"Crop Distribution"}>
              {" "}
              <InsightsPieChart />{" "}
            </ColumnRow>
            <ColumnRow title={"Rotation Frequencies"}>
              {" "}
              <InsightsRadarChart />{" "}
            </ColumnRow>
            <ColumnRow title={"Other Statistics"}>
              {" "}
              <InsightsCounts />{" "}
            </ColumnRow>
          </div>
        </main>
      </div>
    </div>
  );
}

function InsightsHeader({ userData }) {
  useEffect(() => {}, [userData]);

  const data = {
    farmersCount: 1400,
    farmsCount: 3640,
    fieldsCount: 11300,
    cropyearsCount: 25323,
  };

  function PartnerCard({ Icon, title, amount }) {
    return (
      <Paper
        elevation={1}
        sx={{
          borderRadius: "16px",
          padding: "40px 24px",
          background: "white",
          display: "flex",
          boxShadow:
            "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
          alignItems: "center",
        }}
      >
        <img className="block h-[50px]   w-[50px] " src={Icon} />
        <div className="ml-5">
          <p className="text-[24px] text-[#F15D22] font-bold ">{amount}</p>
          <p className="text-[24px] text-[#919EAB]">{title}</p>
        </div>
      </Paper>
    );
  }
  return (
    <section className="w-full mb-8">
      <Typography variant="h2" sx={{ fontWeight: 500 }} gutterBottom>
        Insights
      </Typography>

      <div className="w-full grid grid-cols-2  min-[1100px]:grid-cols-4 mt-6 gap-4 transition-[all] duration-300   mt-6 gap-4">
        <PartnerCard
          Icon={FarmerIcon}
          title={"Farmers"}
          amount={data.farmersCount}
        />
        <PartnerCard Icon={FarmIcon} title={"Farms"} amount={data.farmsCount} />
        <PartnerCard
          Icon={FieldIcon}
          title={"Fields"}
          amount={data.fieldsCount}
        />
        <PartnerCard
          Icon={CropyearIcon}
          title={"Rotations"}
          amount={data.cropyearsCount}
        />
      </div>
    </section>
  );
}

function ProfileSection({ userData }) {
  useEffect(() => {}, [userData]);

  function PartnerCard({ children }) {
    return (
      <Paper
        elevation={1}
        sx={{
          borderRadius: "16px",
          padding: "24px 24px",
          boxShadow:
            "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
          background: "white",
          color: "black",
        }}
      >
        {children}
      </Paper>
    );
  }
  return (
    <section className="w-full mb-8">
      <Typography variant="h2" sx={{ fontWeight: 500 }} gutterBottom>
        Partner Profile
      </Typography>

      <div className="w-full grid grid-cols-3 min-[1100px]:grid-cols-4 mt-6 gap-4 transition-[all] duration-300">
        <PartnerCard>
          <Typography sx={{ marginBottom: 0 }} variant="h5" gutterBottom>
            {userData.name}
          </Typography>
        </PartnerCard>
        <PartnerCard>
          <Typography sx={{ marginBottom: 0 }} variant="h5" gutterBottom>
            {userData.subcription_type.charAt(0) +
              userData.subcription_type.slice(1).toLowerCase() +
              " Subscription"}
          </Typography>
        </PartnerCard>
        {userData.subcription_type === "FREE" && (
          <PartnerCard>
            <Typography sx={{ marginBottom: 0 }} variant="h5" gutterBottom>
              {"Available Quota: " +
                `${userData.generated_reports}/${userData.max_reports}`}
            </Typography>
          </PartnerCard>
        )}
      </div>
    </section>
  );
}

function InsightsMap({ userData }) {
  const [stats, setStats] = useState("");
  const handleStats = (e) => {
    setStats(e);
  };

  return (
    <div
      style={{
        width: "800px",
        height: "500px",
        overflow: "hidden",
      }}
    >
      <CanadaMap setTooltipContent={handleStats} />
      <Tooltip type="dark">{stats}</Tooltip>
    </div>
  );
}

class InsightsBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: [44, 55, 41, 64, 22, 43, 21],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },

        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        colors: ["#F15D22"],
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
        },
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={430}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

class InsightsPieChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [44, 55, 13, 43, 22],
      options: {
        chart: {
          type: "pie",
        },
        labels: ["Corn", "Spring Wheat", "Winter Wheat", "Rye", "Soybean"],
        fill: {},
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="pie"
            width={"90%"}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

class InsightsRadarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Series 1",
          data: [80, 50, 30, 40, 100, 20, 150],
        },
      ],
      options: {
        chart: {
          type: "radar",
        },
        colors: ["#F15D22"],

        yaxis: {
          stepSize: 20,
        },
        xaxis: {
          categories: [
            "Continuous",
            "Every 5 years",
            "Every 4 years",
            "Every 3 years",
            "Every 2 years",
            "Two out of 3 years",
            "Two out of 5 years",
          ],
          style: {
            colors: "#000000", // Set label font color to black
          },
        },
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="radar"
            width="100%"
          />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

function InsightsCounts({}) {
  const data = [
    {
      Icon: AcresIcon,
      value: 123002,
      unit: "Acres",
    },
    {
      Icon: FertilizerIcon,
      value: 5000,
      unit: "Fertilizer Tonnes",
    },
    {
      Icon: MachinesIcon,
      value: 110039,
      unit: "Machines",
    },
    {
      Icon: MachineHours,
      value: 4000001,
      unit: "Machine Hours",
    },
  ];
  return (
    <div className="w-full h-[90%] grid grid-cols-2 gap-4">
      {data.map((value, idx) => {
        return (
          <div
            key={idx}
            className="flex flex-col items-center justify-center rounded-2xl border-[1px] border-dashed border-slate-300"
          >
            <img
              // style={{
              //   filter:
              //     "invert(52%) sepia(100%) saturate(748%) hue-rotate(-17deg) brightness(98%) contrast(85%)",
              // }}
              className="h-[35%]"
              src={value.Icon}
              alt="icon"
            />
            <p className="text-xl font-black text-[#F15D22]">{value.value}</p>
            <p className="text-slate-500 font-medium">{value.unit}</p>
          </div>
        );
      })}
    </div>
  );
}

function ColumnRow({ title, children }) {
  return (
    <div className="w-full h-full bg-white rounded-2xl p-4 shadow-lg">
      <Typography sx={{ fontWeight: 600 }} variant="h6" gutterBottom>
        {title}
      </Typography>
      {children}
    </div>
  );
}
