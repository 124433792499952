import * as React from "react";
import Button from "@mui/material/Button";
// import DeleteIcon from "@mui/icons-material/Delete";
// import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useNavigate } from "react-router-dom";
export default function Nav({ activePage }) {
  const navigate = useNavigate();

  function handleClick() {
    if (activePage == "report") navigate("/" + "dashboard");
    else navigate("/" + "report");
  }

  return (
    <div className="h-full left-0   top-0 w-[60px] bg-white  min-[1100px]:w-[200px] border-r-[1px] border-slate-200 transition-[all] duration-300 p-4 z-50">
      <Stack direction="column" spacing={1}>
        <Button
          onClick={handleClick}
          variant="text"
          startIcon={<AccountBoxIcon style={{ fontSize: "25px" }} />}
          style={{
            justifyContent: "flex-start",
            fontSize: "16px",
            color: activePage !== "dashboard" && "black",
          }}
        >
          <p
            className={
              "text-transparent  min-[1100px]:text-black transition-[all] duration-300"
            }
          >
            Dashboard
          </p>
        </Button>
        <Button
          onClick={handleClick}
          variant="text"
          startIcon={<AssessmentIcon style={{ fontSize: "25px" }} />}
          style={{
            justifyContent: "flex-start",
            fontSize: "16px",
            color: activePage !== "report" && "black",
          }}
        >
          <p className="text-transparent  min-[1100px]:text-black transition-[all] duration-300">
            Reports
          </p>
        </Button>
      </Stack>
    </div>
  );
}
